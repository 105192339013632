/* eslint-disable react/prop-types */
import React from "react";
import { CardHeader, TextField } from "@mui/material";

const flex = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
};

const SocialMediaHandlename = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur
}) => {
  return (
    <div style={{ ...flex, width: "100%" }}>
      <CardHeader
        subheader="Enter your Handle name to view your current social media analytics & summary"
        title="Social Media"
        sx={{ m: 0, p: 0, ml: 3 }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gridGap: ".85rem",
          width: "100%",
          padding: "1rem",
          paddingTop: "0%"
        }}
      >
        <TextField
          autoComplete="off"
          name="instagram_handle_name"
          id="instagram_handle_name"
          label="Instagram Handle name"
          placeholder="Enter Your Intagram Handle name"
          value={values.instagram_handle_name}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth         
          helperText={
            touched.instagram_handle_name && errors.instagram_handle_name
          }
          error={errors.instagram_handle_name && touched.instagram_handle_name}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="twitter_handle_name"
          id="twitter_handle_name"
          label="Twitter Handle name"
          placeholder="Enter Your Twitter Handle name"
          value={values.twitter_handle_name}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth         
          helperText={touched.twitter_handle_name && errors.twitter_handle_name}
          error={errors.twitter_handle_name && touched.twitter_handle_name}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="youtube_handle_name"
          id="youtube_handle_name"
          label="Youtube Handle name"
          placeholder="Enter Your YouTube Handle name"
          value={values.youtube_handle_name}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.youtube_handle_name && errors.youtube_handle_name}
          error={errors.youtube_handle_name && touched.youtube_handle_name}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="TikTok_handle_name"
          id="TikTok_handle_name"
          label="TikTok Handle name"
          placeholder="Enter Your TikTok Handle name"
          value={values.tiktok_handle_name}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.tiktok_handle_name && errors.tiktok_handle_name}
          error={errors.tiktok_handle_name && touched.tiktok_handle_name}
          style={{ marginTop: "1rem" }}
        />
      </div>
    </div>
  );
};

export default SocialMediaHandlename;
