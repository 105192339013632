/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import { Button, CardHeader, TextField, MenuItem } from "@mui/material";
import * as Yup from "yup";

const flex = {
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
};

// Validation schema
const validationSchema = Yup.object({
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is a required field"),
});

const countries = [
  { code: "IN", name: "India", flag: "https://flagcdn.com/w320/in.png" },
  { code: "AE", name: "UAE", flag: "https://flagcdn.com/w320/ae.png" },
  { code: "VN", name: "Vietnam", flag: "https://flagcdn.com/w320/vn.png" },
  { code: "SG", name: "Singapore", flag: "https://flagcdn.com/w320/sg.png" },
  { code: "MY", name: "Malaysia", flag: "https://flagcdn.com/w320/my.png" },
  { code: "TH", name: "Thailand", flag: "https://flagcdn.com/w320/th.png" },
  { code: "ID", name: "Indonesia", flag: "https://flagcdn.com/w320/id.png" },
  { code: "AU", name: "Australia", flag: "https://flagcdn.com/w320/au.png" },
  { code: "KR", name: "Korea", flag: "https://flagcdn.com/w320/kr.png" },
];

const LocationDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  return (
    <div style={{ ...flex, width: "100%" }}>
      <CardHeader
        title="Your Location"
        sx={{ m: 0, p: 0, mt: 0.5, ml: 3, width: "95%" }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
          gridGap: ".85rem",
          width: "100%",
          padding: "1rem",
          paddingTop: "0%",
        }}
      >
        <TextField
          autoComplete="off"
          name="address_1"
          id="address_1"
          label="Address"
          placeholder="Enter Your Address"
          value={values.address_1}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.address_1 && errors.address_1}
          error={errors.address_1 && touched.address_1}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="city"
          id="city"
          label="City"
          placeholder="Enter Your City"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.city && errors.city}
          error={errors.city && touched.city}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="state"
          id="state"
          label="State"
          required
          placeholder="Enter Your State"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.state && errors.state}
          error={errors.state && touched.state}
          style={{ marginTop: "1rem" }}
        />
        <TextField
          autoComplete="off"
          name="country"
          id="country"
          label="Country"
          placeholder="Select Your Country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          required
          select
          helperText={touched.country && errors.country}
          error={errors.country && touched.country}
          style={{ marginTop: "1rem" }}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "24px", height: "18px", objectFit: "cover" }}
                />
                {country.name}
              </div>
            </MenuItem>
          ))}
        </TextField>
        <TextField
          autoComplete="off"
          name="zipcode"
          id="zipcode"
          label="Zip Code"
          placeholder="Enter Your Zip Code"
          value={values.zipcode}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          helperText={touched.zipcode && errors.zipcode}
          error={errors.zipcode && touched.zipcode}
          style={{ marginTop: "1rem" }}
        />
      </div>
    </div>
  );
};

export default LocationDetails;
