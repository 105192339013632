/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompetitors,
  insertCompetitor,
} from "../../context/slice/userSlice";
import { apihandleActions } from "../../context/slice/ApiCalls";
import VerifyLinkTextField from "../shared/VerifyLinkTextField";
import { unwrapResult } from "@reduxjs/toolkit";

const CompetitorSchema = Yup.object({
  fullName: Yup.string(),
  socialmedia: Yup.string().required("Socialmedia is required"),
  handlename: Yup.string().required("Handle name is required"),
  companyLink: Yup.string(),
});

const AddCompetitorsForm = ({ handleClose, setAddCompetitorError }) => {
  const [valid, setValid] = useState("");
  const { user, competitors } = useSelector((store) => store.users);
  const dispatch = useDispatch();
  const initialValues = {
    fullName: "",
    companyLink: "",
    socialmedia: "",
    handlename: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema: CompetitorSchema,
      onSubmit: async (values) => {
        const data = {
          user: user?.user?.id,
          competitor_full_name: values.fullName,
          competitor_handle_name: values.handlename.toLowerCase(),
          competitor_social_media: values.socialmedia.toLowerCase(),
          competitor_website: values.companyLink,
        };
        if (valid === "valid") {
          await dispatch(insertCompetitor(data))
            .then(unwrapResult)
            .then(() => {
              dispatch(getCompetitors());
            })
            .catch(() => setAddCompetitorError(true));
          dispatch(apihandleActions.startCompetitorsCall());
          competitors?.length === 0 &&
            dispatch(apihandleActions.startProfilecompleteCall());
          handleClose();
        }
      },
    });

  return (
    <form style={{ margin: "1rem 0" }} className="competitors__form">
      <div className="competitors__form-group">
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Enter Name"
          name="fullName"
          id="fullName"
          label="Name"
          error={errors.fullName && touched.fullName}
          value={values.fullName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.fullName && touched.fullName ? (
          <p className="form-error">{errors.fullName}</p>
        ) : null}
      </div>
      <div style={{ margin: "1rem 0" }} className="competitors__form-group">
        <TextField
          id="socialmedia"
          name="socialmedia"
          value={values.socialmedia}
          select
          helperText={touched.socialmedia && errors.socialmedia}
          error={Boolean(errors.socialmedia) && touched.socialmedia}
          label="Social Media"
          onChange={handleChange}
          fullWidth
          onBlur={handleBlur}
        >
          <MenuItem value={"instagram"}>Instagram</MenuItem>
          <MenuItem value={"youtube"}>YouTube</MenuItem>
          <MenuItem value={"twitter"}>Twitter</MenuItem>
          <MenuItem value={"tiktok"}>TikTok</MenuItem>
        </TextField>
      </div>
      <div style={{ margin: "1rem 0" }} className="competitors__form-group">
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          placeholder="Handle Name"
          name="handlename"
          id="handlename"
          label="Handle name"
          error={errors.handlename && touched.handlename}
          value={values.handlename}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {errors.handlename && touched.handlename ? (
          <p className="form-error">{errors.handlename}</p>
        ) : null}
      </div>
      <div className="competitors__form-group">
        <VerifyLinkTextField
          autoComplete="off"
          name="companyLink"
          id="companyLink"
          label="Company Link"
          placeholder="Enter Your Company Link"
          value={values.companyLink}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched.companyLink}
          fullWidth
          error={errors.companyLink && touched.companyLink}
          logic={"VerifyLink"}
          style={{ marginTop: "1rem" }}
          valid={valid}
          setValid={setValid}
        />
      </div>
      <div
        style={{ margin: "1rem 0", width: "100%" }}
        className="user__profile-btns"
      >
        <Button onClick={handleSubmit} type="submit" variant="contained">
          Add
        </Button>
        <Button
          onClick={handleClose}
          style={{ marginLeft: "1rem" }}
          variant="outlined"
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default AddCompetitorsForm;
